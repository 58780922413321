"use client";
import * as m from "@/../paraglide/messages";

import { experimental_extendTheme as extendTheme } from "@mui/material/styles";

import { ptBR, esES } from "@mui/material/locale";
import { languageTag } from "../../paraglide/runtime";

const currentLanguage = languageTag();
const locale = currentLanguage === "es" ? esES : ptBR;

export const theme = extendTheme(
  {
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    colorSchemes: {
      light: {
        palette: {
          // palette values for light mode
          primary: {
            main: "#0D1E26",
          },
          secondary: {
            main: "#e5e5e5",
          },
          background: {
            paper: "#e5e5e5",
          },
        },
      },
      dark: {
        palette: {
          primary: {
            main: "#e5e5e5",
          },
          text: {
            primary: "#e5e5e5",
          },
          background: {
            default: "#0D1E26",
            paper: "#0D1E26",
          },
        },
      },
    },
  },
  locale
);
