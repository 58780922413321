"use client";
// DashboardFetchProvider.tsx
import React, { createContext, useEffect, useState } from "react";

import { usePathname } from "@/lib/i18n";

import {
  docsPing,
  healthCheck,
  refreshTokensAndSetCookies,
} from "@/lib/actions";

import { SWRConfig } from "swr";

const FETCH_INTERVAL = 8 * 60 * 1000; // 8 minutes in milliseconds

interface DashboardFetchContextProps {
  loading: boolean;
  hydrated: boolean;
}

const defaultValue: DashboardFetchContextProps = {
  loading: false,
  hydrated: false,
};

const DashboardFetchContext =
  createContext<DashboardFetchContextProps>(defaultValue);

interface DashboardFetchProviderProps {
  children: React.ReactNode;
}

export const DashboardFetchProvider: React.FC<DashboardFetchProviderProps> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hydrated, setHydrated] = useState<boolean>(false);

  // OLD LocalStorage Logic
  // const pathname = usePathname();
  // const { refreshData } = useLocalStorageContext();

  //TODO: MIGRATE TO SWR ON CLIENT COMPONENTS

  // useEffect(() => {
  //   if (!pathname.startsWith("/dashboard")) {
  //     return;
  //   }

  //   const fetchData = async () => {
  //     console.log("fetchData() called");
  //     const cacheDuration = 8 * 60 * 1000; // 8 minutes in milliseconds
  //     const cachedTimestamp = localStorage.getItem("cachedTimestamp");

  //     if (cachedTimestamp) {
  //       const age = Date.now() - parseInt(cachedTimestamp, 10);
  //       if (age < cacheDuration) {
  //         console.log("Data check passed, age is younger than 8 minutes");
  //         setHydrated(true);
  //         setLoading(false);
  //         return;
  //       }
  //     }
  //     const accessToken = (await getCookies())?.access_token;
  //     console.log("Access token found:", accessToken);
  //     if (!accessToken) {
  //       console.log("No access token found.");
  //       window.location.href = "/auth/login";
  //       return;
  //     }
  //     try {
  //       console.log("Fetching general data... (try/catch)");
  //       setLoading(true);

  //       const response = await fetch("/api/fetchGeneralData");
  //       console.log("Response received:", response);

  //       const result = await response.json();
  //       console.log("JSON parsed:", result);

  //       if (response.status === 200) {
  //         // Helper function to handle localStorage setting and logging
  //         const handleStorage = (key, data, status) => {
  //           if (status === 200) {
  //             localStorage.setItem(key, JSON.stringify(data));
  //           } else {
  //             console.warn(`Status for ${key} is not 200:`, status);
  //           }
  //         };
  //         console.log("response.status is 200, handling storage");

  //         handleStorage(
  //           "usuariosData",
  //           result.usuarios?.data,
  //           result.usuarios?.status
  //         );
  //         handleStorage(
  //           "enderecos",
  //           result.enderecos?.data,
  //           result.enderecos?.status
  //         );
  //         handleStorage(
  //           "produtos",
  //           result.produtos?.data,
  //           result.produtos?.status
  //         );
  //         handleStorage(
  //           "filiais",
  //           result.filiais?.data,
  //           result.filiais?.status
  //         );
  //         handleStorage("docasData", result.docas?.data, result.docas?.status);
  //         handleStorage(
  //           "categoriaData",
  //           result.categoria?.data,
  //           result.categoria?.status
  //         );
  //         handleStorage(
  //           "funcaoData",
  //           result.funcao?.data,
  //           result.funcao?.status
  //         );
  //         handleStorage(
  //           "fornecedorData",
  //           result.fornecedor?.data,
  //           result.fornecedor?.status
  //         );
  //         handleStorage("secaoData", result.secao?.data, result.secao?.status);
  //         handleStorage(
  //           "depositosData",
  //           result.depositos?.data,
  //           result.depositos?.status
  //         );
  //         handleStorage(
  //           "departamentoData",
  //           result.departamento?.data,
  //           result.departamento?.status
  //         );
  //         handleStorage(
  //           "clientesData",
  //           result.clientes?.data,
  //           result.clientes?.status
  //         );
  //         handleStorage(
  //           "motivosData",
  //           result.motivos?.data,
  //           result.motivos?.status
  //         );
  //         handleStorage(
  //           "movimentacaoData",
  //           result.movimentacao?.data,
  //           result.movimentacao?.status
  //         );
  //         handleStorage(
  //           "areaArmazenagemData",
  //           result.areaArmazenagem?.data,
  //           result.areaArmazenagem?.status
  //         );

  //         localStorage.setItem("cachedTimestamp", Date.now().toString());
  //         localStorage.setItem("userData", JSON.stringify(result.user?.data));
  //         console.log("Stored user and timestamp data successfully");

  //         refreshData();
  //         setHydrated(true);
  //       } else if (response.status === 201) {
  //         console.log(
  //           "Refresh token used successfully, calling fetchData again."
  //         );
  //         return fetchData();
  //       } else {
  //         console.warn("Response not OK:", response.status);
  //         console.warn("Failure details:", response);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching general data");
  //     } finally {
  //       console.log("Finally setting loading to false");
  //       setLoading(false);
  //     }
  //   };

  //   const fetchDataInterval = setInterval(fetchData, FETCH_INTERVAL);

  //   fetchData();
  //   return () => clearInterval(fetchDataInterval);
  // }, [pathname, refreshData]);
  const pathname = usePathname();
  useEffect(() => {
    if (!pathname.startsWith("/dashboard")) {
      return;
    }

    const intervalId = setInterval(() => {
      const ping = async () => {
        console.log("Running healthCheck and docsPing");
        await healthCheck();
        await docsPing();
      };
      ping();
    }, 3 * 60 * 1000); // 3 minutes in milliseconds

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [pathname]);

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
      }}
    >
      <DashboardFetchContext.Provider value={{ loading, hydrated }}>
        {children}
      </DashboardFetchContext.Provider>
    </SWRConfig>
  );
};

export const useDashboardFetch = () => React.useContext(DashboardFetchContext);
