import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/@fontsource/roboto/300.css");
;
import(/* webpackMode: "eager", webpackExports: ["ClientLanguageProvider"] */ "/opt/render/project/src/node_modules/@inlang/paraglide-next/dist/paraglide-next/src/app/providers/ClientLanguageProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["Experimental_CssVarsProvider"] */ "/opt/render/project/src/node_modules/@mui/material/styles/CssVarsProvider.js");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/@fontsource/roboto/400.css");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/@fontsource/roboto/500.css");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/@fontsource/roboto/700.css");
;
import(/* webpackMode: "eager", webpackExports: ["theme"] */ "/opt/render/project/src/src/lib/mui-create-theme.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DashboardFetchProvider"] */ "/opt/render/project/src/src/providers/dashboardContextProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocalStorageProvider"] */ "/opt/render/project/src/src/providers/LocalStorageProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/src/providers/themeModeProvider.tsx");
