import { clsx, type ClassValue } from "clsx";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function normalizeString(str: string) {
  return str
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .trim();
}

export function filterData<T>(searchInput: string, data: T[]): T[] {
  if (searchInput !== "" && data.length > 0) {
    // Normalize the search input
    // if searchInput contains only digits
    if (/^\d+$/.test(searchInput)) {
      const filteredData = data.filter((row) => {
        if (row.hasOwnProperty("id")) {
          return parseInt(row["id"]) === parseInt(searchInput);
        }
      });

      return filteredData;
    } else {
      // Filter by descricao
      const filteredData = data.filter((row) => {
        //Check if data contains row descricao
        if (row.hasOwnProperty("descricao")) {
          //Check if row descricao contains normalizedSearchInput
          return normalizeString(row["descricao"]).includes(
            normalizeString(searchInput)
          );
        }
        if (row.hasOwnProperty("username")) {
          //Check if row descricao contains normalizedSearchInput
          return normalizeString(row["username"]).includes(
            normalizeString(searchInput)
          );
        }

        // Return the filtered data
      });
      return filteredData;
    }
  } else {
    return data;
  }
}

export const tokenExpiredLogout = () => {
  toast.error("Sua sessão expirou, faca login novamente");
  //redirect to login
  setTimeout(() => {
    window.location.href = "/auth/login";
  }, 2000);
  window.location.href = "/auth/login";
};

export const getProgress = (pedidos, data_fim) => {
  const total = pedidos?.length;
  if (total === 0) return 0;
  const conferidos = pedidos?.reduce((count, pedido) => {
    return pedido?.data_fim !== null ? count + 1 : count;
  }, 0);
  return conferidos / total;
};

export const formatToBrazilianReal = (value: number) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

export const updateObjectInArray = (resData, oldData, setState, idKey) => {
  resData.id = idKey;
  const updatedData = oldData.map((item) => {
    if (item.id === idKey) {
      return { ...resData };
    }
    return item;
  });
  setState(updatedData);
};

export const numberInputOnWheelPreventChange = (e) => {
  // Prevent the input value change
  e.target.blur();

  // Prevent the page/container scrolling
  e.stopPropagation();

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    e.target.focus();
  }, 0);
};

export const checkAge = (timestampKey) => {
  const cacheDuration = 8 * 60 * 1000; // 8 minutes in milliseconds
  const cachedTimestamp = localStorage.getItem(timestampKey);
  if (cachedTimestamp) {
    const age = Date.now() - parseInt(cachedTimestamp, 10);

    return age > cacheDuration;
  } else {
    return true;
  }
};

export const formatCNPJ = (cnpj) => {
  // Remove todos os caracteres que não são dígitos
  const cleanedCNPJ = cnpj.replace(/\D/g, "");

  // Verifica se o CNPJ limpo tem exatamente 14 dígitos
  if (cleanedCNPJ.length !== 14) {
    return "CNPJ inválido";
  }

  // Aplica a formatação correta
  return `${cleanedCNPJ.slice(0, 2)}.${cleanedCNPJ.slice(
    2,
    5
  )}.${cleanedCNPJ.slice(5, 8)}/${cleanedCNPJ.slice(8, 12)}-${cleanedCNPJ.slice(
    12,
    14
  )}`;
};
