"use client";
import * as m from "@/../paraglide/messages";

// context/LocalStorageContext.tsx
import React, { createContext, useContext, useState } from "react";

interface LocalStorageContextProps {
  refreshData: () => void;
  trigger: number;
}

const LocalStorageContext = createContext<LocalStorageContextProps>({
  refreshData: () => {},
  trigger: 0,
});

export const LocalStorageProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [trigger, setTrigger] = useState(0);

  const refreshData = () => {
    setTrigger((prev) => prev + 1);
  };

  return (
    <LocalStorageContext.Provider value={{ refreshData, trigger }}>
      {children}
    </LocalStorageContext.Provider>
  );
};

export const useLocalStorageContext = () => useContext(LocalStorageContext);
