"use client";
import * as m from "@/../paraglide/messages";

import { cn } from "@/lib/utils";
import React, { createContext, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useColorScheme } from "@mui/material/styles";
import InitColorSchemeScript from "@mui/material/InitColorSchemeScript";

export const colorSchemeContext = createContext({} as any);

const ThemeModeProvider = ({ children }: { children: React.ReactNode }) => {
  const { mode, setMode } = useColorScheme();
  const [colorMode, setColorMode] = useState("light");

  useEffect(() => {
    setColorMode(mode);
  }, [mode]);
  return (
    <colorSchemeContext.Provider value={{ colorMode, setMode }}>
      <InitColorSchemeScript />
      <div className={cn(colorMode === "dark" ? "dark" : "", "h-full")}>
        <Toaster />
        {children}
      </div>
    </colorSchemeContext.Provider>
  );
};

export default ThemeModeProvider;
